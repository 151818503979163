<template>
  <VDialog v-model="show" :persistent="true" :max-width="600" transition="slide-y-reverse-transition">
    <v-card>
      <v-card-title>
        {{ $t('help_dialog_title') }}
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-4">
        <div class="subtitle-2">
          {{ $t('help_dialog_do_you_have_questions') }}
        </div>
        <v-row align="center">
          <v-col class="grow">
            <div class="caption">
              {{ $t('help_dialog_further_information_help_center') }}
            </div>
          </v-col>
          <v-col class="shrink">
            <v-btn href="https://docs.4teamwork.ch/SAP/" target="_blank" small outlined color="primary">
              {{ $t('help_dialog_help_center') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-text class="py-3">
        <div v-dompurify-html="helpDialogContent"></div>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn depressed @click="show = false">
          {{ $t('help_dialog_close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </VDialog>
</template>

<script>
import Vue from 'vue'
import VueDOMPurifyHTML from 'vue-dompurify-html'

Vue.use(VueDOMPurifyHTML)
export default {
  name: 'HelpDialog',
  data() {
    return {
      show: false,
    }
  },
  computed: {
    helpDialogContent() {
      return this.$config.help_page_html_content.replace(/\n/g, '')
    },
  },
  methods: {
    open() {
      this.show = true
    },
  },
}
</script>
